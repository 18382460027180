import { URL_CONFIG } from './consts';

export function formatMoney(num: number, locale?: string, currency?: string) {
	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency,
	}).format(num);
}

/**
 * Note: We can't use dollar sign or brackets eg: ${PARAM} or $PARAM, because vite.js internaly expand env.variables, so, I had to come up with the (MY_VAR) pattern
 */
export function generateURL(
	url: string,
	options: Record<string, string | number>
) {
	return Object.keys(options).reduce((url, key) => {
		return url.split('(' + key + ')').join(options[key].toString());
	}, url);
}

export function addParamsToURL(url: string, params: any) {
	let newUrl = generateURL(url, URL_CONFIG);
	let isFirstParam = true;

	for (const key in params) {
		if (params[key]) {
			const value = params[key].toString();

			if (!value.includes('not-set')) {
				if (isFirstParam) {
					newUrl += '?';
					isFirstParam = false;
				} else {
					newUrl += '&';
				}

				newUrl +=
					encodeURIComponent(key) + '=' + encodeURIComponent(value);
			}
		}
	}

	return newUrl;
}

export function bgURL(url: string) {
	return `url("${url}")`;
}

export function pluralize(
	value: number,
	obj: Record<number, string>,
	fallback: string
) {
	if (obj[value] !== undefined) {
		return obj[value];
	}
	return fallback;
}
