import { useFirebase, useObject } from '@hooks/useFirebase';
import { getDatabase, ref } from 'firebase/database';
import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

type SettingsType = {
	status: string;
};

export const SettingsProviderContext = createContext({} as SettingsType);

export function SettingsProvider({
	config,
	children,
}: PropsWithChildren<{
	config: any;
}>) {
	const [newStatus, setNewStatus] = useState<any>(null);

	const settingsApp = useFirebase('settings', {
		databaseURL: config?.settings_meta?.databaseUrl,
	});
	const [status] = useObject(ref(getDatabase(settingsApp), '/showPip'));

	useEffect(() => {
		setNewStatus(status?.val());
	}, [settingsApp, status]);

	return (
		<SettingsProviderContext.Provider value={{ status: newStatus }}>
			{children}
		</SettingsProviderContext.Provider>
	);
}

export function useSettings() {
	return useContext(SettingsProviderContext);
}
