import React from 'react';

export function PlayIcon() {
	return (
		<svg
			width="27"
			height="30"
			viewBox="0 0 27 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.66788 28.7218C3.55676 29.9416 0.916992 28.4181 0.916992 25.9799V4.02029C0.916992 1.58213 3.55676 0.0586111 5.66786 1.27836L24.6714 12.2583C26.7814 13.4773 26.7814 16.523 24.6714 17.742L5.66788 28.7218Z"
				fill="white"
			/>
		</svg>
	);
}
