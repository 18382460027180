import { CloseButton } from '@components/LiveOverlay/Button/Close';
import { MuteIcon } from '@assets/Icons/MuteIcon';
import { PipIcon } from '@assets/Icons/PipIcon';
import { PlayIcon } from '@assets/Icons/PlayIcons';
import { SoundIcon } from '@assets/Icons/SoundIcon';
import { LiveTag } from '@components/LiveOverlay/Tag/Live';
import { useConfig } from '@providers/config.provider';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsDragging } from '@providers/dragging.provider';

export type LiveControlProps = {
	handleLiveClose: () => void;
	handleMute: (e: any) => void;
	handleGoLive: (e: any) => void;
	handleScrollToLive: (e: any) => void;
	onPlay: () => void;
	isMuted: boolean;
	isLivePage: boolean;
	isPlaying: boolean;
	cantMove: boolean;
};

export default function LiveControl(props: LiveControlProps) {
	const [onHover, setOnHover] = useState<boolean>(false);
	const [initialTime, setInitialTime] = useState<Date | null>(null);
	const { config } = useConfig();
	const { t } = useTranslation();
	const { isDragging } = useIsDragging();

	const onPressing = () => {
		setInitialTime(new Date());
	};

	const onRelease = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		if (initialTime !== null) {
			const currentTime = new Date();
			const timeDifference =
				currentTime.getTime() - initialTime.getTime();

			if (timeDifference < 150) {
				props.handleGoLive(e);
			}

			setInitialTime(null);
		}
	};

	return (
		<>
			{!props.isPlaying && (
				<div className="mimo-pip--absolute mimo-pip--flex mimo-pip--items-center mimo-pip--justify-center mimo-pip--w-full mimo-pip--h-full mimo-pip--z-[20]">
					<div
						className={'mimo-pip--cursor-pointer'}
						onClick={props.onPlay}
						onTouchEnd={props.onPlay}
					>
						<PlayIcon />
					</div>
				</div>
			)}
			{window.innerWidth < 768 && (
				<div
					className="mimo-pip--absolute"
					style={{
						top: '-6px',
						right: '-12px',
						zIndex: '2147483647',
					}}
				>
					<div className="mimo-pip--cursor-pointer mimo-pip--flex mimo-pip--justify-center mimo-pip--items-center">
						<CloseButton onClick={() => props.handleLiveClose()} />
					</div>
				</div>
			)}
			{onHover && (
				<div
					className="mimo-pip--absolute"
					style={{
						top: '-6px',
						right: '-12px',
						zIndex: '2147483647',
					}}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				>
					<div className="mimo-pip--cursor-pointer mimo-pip--flex mimo-pip--justify-center mimo-pip--items-center">
						<CloseButton onClick={() => props.handleLiveClose()} />
					</div>
				</div>
			)}
			{onHover && (
				<div
					style={{ zIndex: '2147483647' }}
					className="mimo-pip--cursor-pointer mimo-pip--absolute mimo-pip--bottom-[10px] mimo-pip--right-[10px]"
					onTouchStart={(v) => props.handleMute(v)}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
					onClick={(e) => {
						e.stopPropagation();
						props.handleMute(e);
					}}
				>
					{!props.isLivePage && (
						<>{!props.isMuted ? <SoundIcon /> : <MuteIcon />}</>
					)}
				</div>
			)}
			{window.innerWidth < 768 && (
				<div
					style={{ zIndex: '2147483647' }}
					className="mimo-pip--cursor-pointer mimo-pip--absolute mimo-pip--bottom-[10px] mimo-pip--right-[10px]"
					onTouchStart={(v) => props.handleMute(v)}
					onClick={(e) => {
						e.stopPropagation();
						props.handleMute(e);
					}}
				>
					{!props.isLivePage && (
						<>{!props.isMuted ? <SoundIcon /> : <MuteIcon />}</>
					)}
				</div>
			)}
			<div
				onMouseEnter={() => setOnHover(true)}
				onMouseLeave={() => setOnHover(!onHover)}
				onTouchStart={() => onPressing()}
				onTouchEnd={(e) => onRelease(e)}
				onClick={(e: any) => {
					if (!isDragging) {
						props.handleGoLive(e);
					}
				}}
				className={`${
					!props.cantMove
						? 'mimo-pip--cursor-default'
						: 'mimo-pip--cursor-grab'
				} mimo-pip--w-full mimo-pip--h-full mimo-pip--absolute mimo-pip--left-0 mimo-pip--top-0 mimo-pip--flex mimo-pip--flex-1 mimo-pip--flex-col mimo-pip--justify-between`}
				style={{
					backgroundColor: onHover ? '#0000004d' : 'rgba(0, 0, 0, 0)',
					borderRadius: '8px',
					zIndex: '2147483646',
				}}
			>
				<div className="mimo-pip--flex mimo-pip--flex-col mimo-pip--w-full mimo-pip--h-full mimo-pip--justify-between">
					<div
						className="mimo-pip--flex mimo-pip--relative mimo-pip--w-full"
						style={{ position: 'relative', width: '100%' }}
					>
						<div className="mimo-pip--p-2 mimo-pip--w-full">
							<LiveTag
								text={t('live')}
								theme={config?.player_settings?.color_tag}
							/>
							{/* <LiveTagIcon theme={config.color_tag} /> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
