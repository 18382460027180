import { ElementPosition } from '@components/ElementPosition';
import { LiveOverlay } from '@components/LiveOverlay';
import { VideoPlayer } from '@components/VideoPlayer';
import { DragProvider } from '@providers/dragging.provider';
import { StageProvider } from '@providers/stage.provider';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function Home() {
	const { t } = useTranslation();
	return (
		<StageProvider>
			<DragProvider>
				<ElementPosition
					child1={<LiveOverlay />}
					child2={<VideoPlayer />}
					t={t}
				/>
			</DragProvider>
		</StageProvider>
	);
}
