import React, {
	createContext,
	useContext,
	useState,
	PropsWithChildren,
} from 'react';

type Dragging = {
	isDragging: boolean;
	setIsDragging: (v: boolean) => void;
};

const DragContext = createContext({} as Dragging);

export function DragProvider(props: PropsWithChildren) {
	const [isDragging, setIsDragging] = useState<boolean>(false);

	return (
		<DragContext.Provider value={{ isDragging, setIsDragging }}>
			{props.children}
		</DragContext.Provider>
	);
}

export function useIsDragging() {
	return useContext(DragContext);
}
