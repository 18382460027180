import React from 'react';

interface EllipseProps {
	color: string;
}

export function Ellipse(props: EllipseProps) {
	return (
		<div
			className={`mimo-pip--rounded-full mimo-pip--animate-pulse mimo-pip--w-1 mimo-pip--h-1 sm:mimo-pip--w-2 sm:mimo-pip--h-2`}
			style={{ backgroundColor: props.color }}
		></div>
	);
}
