import React from 'react';

export function MuteIcon() {
	return (
		<svg
			width="22"
			height="21"
			viewBox="0 0 22 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.4142 0.999921C2.02367 0.609401 1.39051 0.609401 0.99998 0.999921C0.60946 1.39045 0.60946 2.02361 0.99998 2.41414L4.58577 5.99992H2C0.89543 5.99992 0 6.89535 0 7.99992V11.9999C0 13.1045 0.89543 13.9999 2 13.9999H6L11.3492 18.585C11.9979 19.141 13 18.6801 13 17.8257V14.4142L18.5858 19.9999C18.9763 20.3905 19.6095 20.3905 20 19.9999C20.3905 19.6094 20.3905 18.9762 20 18.5857L2.4142 0.999921ZM6.51763 7.93178L11 12.4142V15.6515L7.30158 12.4814C6.9391 12.1707 6.47742 11.9999 6 11.9999H2V7.99992H6C6.17608 7.99992 6.35002 7.97669 6.51763 7.93178Z"
				fill="white"
			/>
			<path
				d="M11 4.34839V6.75713L13 8.75713V2.17417C13 1.31982 11.9979 0.858909 11.3492 1.41492L8.28461 4.04173L9.70301 5.46012L11 4.34839Z"
				fill="white"
			/>
			<path
				d="M15.7632 11.5203L17.2981 13.0552C17.358 12.9315 17.4144 12.806 17.4672 12.6787C17.8189 11.8294 18 10.9192 18 9.99989C18 9.08069 17.8189 8.17039 17.4672 7.32113C17.2433 6.78063 16.953 6.27157 16.604 5.8053C16.2731 5.36315 15.6332 5.36675 15.2426 5.75727C14.8521 6.1478 14.8623 6.77694 15.1675 7.23726C15.3446 7.50452 15.4961 7.78883 15.6194 8.08649C15.8707 8.69309 16 9.34329 16 9.99989C16 10.5167 15.9199 11.0296 15.7632 11.5203Z"
				fill="white"
			/>
			<path
				d="M18.7387 14.4958L20.2287 15.9858C20.5926 15.4247 20.9055 14.8303 21.1627 14.2094C21.7155 12.8749 22 11.4445 22 9.99992C22 8.55542 21.7155 7.12501 21.1627 5.79043C20.7409 4.77213 20.1692 3.82483 19.4682 2.97929C19.1069 2.54347 18.4537 2.54628 18.0534 2.94658C17.6531 3.34688 17.6584 3.99266 18.0103 4.43609C18.526 5.08586 18.9499 5.8055 19.2686 6.57496C19.7184 7.66081 19.9499 8.82462 19.9499 9.99992C19.9499 11.1752 19.7184 12.3391 19.2686 13.4249C19.1157 13.7941 18.9386 14.1518 18.7387 14.4958Z"
				fill="white"
			/>
		</svg>
	);
}
