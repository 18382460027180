import { MimoPlayerOptions } from '@utils/types';
import React, { PropsWithChildren, createContext, useContext } from 'react';

type EmbedParamsProviderProps = {
	options: MimoPlayerOptions;
};

const Context = createContext<EmbedParamsProviderProps['options']>({
	configEndpoint: 'configEndpoint-not-set',
	debug: false,
	liveId: 'live-id-not-set',
	customerId: 'customer-id-not-set',
	useShadowDom: true,
});

export default function EmbedParamsProvider(
	props: PropsWithChildren<EmbedParamsProviderProps>
) {
	return (
		<Context.Provider value={props.options}>
			{props.children}
		</Context.Provider>
	);
}

export const useEmbedParams = function () {
	return useContext(Context);
};
