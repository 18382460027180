import React from 'react';
import { LiveStreamIcon } from '@assets/Icons/LiveStreamIcon';
import { COLOR_TAG } from '@utils/consts';

interface OpenPipProps {
	title: string;
	text: string;
	theme: string;
	position: string;
	onClick: (v: any) => void;
}

export function OpenPip(props: OpenPipProps) {
	const borderRounded: any = {
		right: 'mimo-pip--rounded-l-lg',
		left: 'mimo-pip--rounded-r-lg',
	};

	return (
		<div
			className={`mimo-pip--h-14 mimo-pip--inline-flex mimo-pip--justify-center mimo-pip--items-start mimo-pip--pl-[18px] mimo-pip--pr-7 mimo-pip--pt-2 mimo-pip--pb-3 ${
				borderRounded[props.position]
			} mimo-pip--space-x-[10px] mimo-pip--cursor-pointer mimo-pip--shadow-[0px_2px_16px_rgba(0,0,0,0.25)]`}
			style={{
				backgroundColor: COLOR_TAG[props.theme].bg,
			}}
			onClick={props.onClick}
		>
			<div className="mimo-pip--pt-1">
				<LiveStreamIcon color={COLOR_TAG[props.theme].cta_icon} />
			</div>
			<div className="mimo-pip--flex mimo-pip--flex-col mimo-pip--justify-start">
				<span
					className="mimo-pip--text-12 mimo-pip--font-semiBold mimo-pip--uppercase mimo-pip--leading-[18px]"
					style={{ color: COLOR_TAG[props.theme].txt }}
				>
					{props.title}
				</span>
				<span
					className="mimo-pip--text-12 mimo-pip--text-white mimo-pip--font-regular mimo-pip--leading-[18px]"
					style={{ color: COLOR_TAG[props.theme].txt }}
				>
					{props.text}
				</span>
			</div>
		</div>
	);
}
