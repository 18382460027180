import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useRef,
	useState,
} from 'react';
import { useTracking } from './tracking.provider';

interface VideoContextProps {
	play: any;
	pause: any;
	mute: any;
	progress: any;
	playerRef: any;
	onPlay: () => void;
	onPause: () => void;
	onMute: (value: any) => void;
	onProgress: (value: any) => void;
}

const VideoContext = createContext({} as VideoContextProps);

export function VideoProvider({ children }: PropsWithChildren) {
	const tracking = useTracking();
	const playerRef = useRef<any>(null);
	const [isMuted, setIsMuted] = useState<boolean>(true);
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [progress, setProgress] = useState<any>(null);

	function onMute(e: any) {
		if (isMuted) {
			tracking.track({
				event: 'pip_unmuted_button',
				event_label: 'Unmuted To Live',
			});
		} else {
			tracking.track({
				event: 'pip_muted_button',
				event_label: 'muted Live',
			});
		}
		e.stopPropagation();
		setIsMuted(!isMuted);
	}

	function onPlay() {
		playerRef?.current?.play();
		setIsPlaying(true);
	}

	function onPause() {
		setIsPlaying(false);
	}

	function onProgress(e: any) {
		setProgress(e);
	}

	return (
		<VideoContext.Provider
			value={{
				play: isPlaying,
				pause: isPlaying,
				mute: isMuted,
				progress: progress,
				playerRef,
				onPlay,
				onPause,
				onMute,
				onProgress,
			}}
		>
			{children}
		</VideoContext.Provider>
	);
}

export function useVideo() {
	return useContext(VideoContext);
}
