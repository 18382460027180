import React from 'react';
import { useVideo } from '@providers/video.provider';
import { useConfig } from '@providers/config.provider';
import { MimoPlayer } from '@mimo-live-sales/mimo-player';

export function VideoPlayer() {
	const video = useVideo();
	const { config } = useConfig();

	return (
		<MimoPlayer
			forceCover
			ref={video.playerRef}
			loop
			muted={video.mute}
			autoplay
			fluid={false}
			tech={config.stream_type}
			src={config.stream_meta.url}
			lowQuality={true}
			debug={true}
			onPlay={() => video.onPlay()}
			onPause={() => video.onPause()}
			onProgress={(value: any) => video.onProgress(value)}
		/>
	);
}
