import React, {
	PropsWithChildren,
	useCallback,
	useContext,
	useMemo,
	useRef,
} from 'react';
import { useConfig } from './config.provider';

type TrackingProviderProps = PropsWithChildren<{
	//
}>;

const Context = React.createContext({
	track: (_event: any) => {},
});

export function useTracking() {
	return useContext(Context);
}

export default function TrackingProvider(props: TrackingProviderProps) {
	const { children } = props;
	const ref = useRef<HTMLIFrameElement>(null);
	const config = useConfig();
	const track = useCallback((event: any) => {
		ref.current?.contentWindow?.postMessage(event, '*');
	}, []);

	const value = useMemo(() => {
		return { track };
	}, [track]);

	return (
		<>
			<iframe
				title="tracking"
				ref={ref}
				src={
					import.meta.env.VITE_BASE_URL +
					import.meta.env.VITE_PACKAGE_VERSION +
					'/tracking/index.html?utm_campaign=' +
					config.config.id +
					'&utm_id=' +
					config.config.id
				}
				style={{
					position: 'absolute',
					width: 0,
					height: 0,
					border: 0,
				}}
			/>
			<Context.Provider value={value}>{children}</Context.Provider>
		</>
	);
}
