import React from 'react';
import { CloseIcon } from '@assets/Icons/CloseIcon';

interface CloseButtonProps {
	onClick: () => void;
}

export function CloseButton(props: CloseButtonProps) {
	return (
		<button
			className="mimo-pip--w-[30px] mimo-pip--h-[30px] mimo-pip--min-w-[30px] mimo-pip--min-h-[30px] mimo-pip--bg-black mimo-pip--rounded-full mimo-pip--flex mimo-pip--justify-center mimo-pip--items-center"
			onClick={props.onClick}
			onTouchStart={props.onClick}
		>
			<CloseIcon />
		</button>
	);
}
