import {
	ApiData,
	ApiHeaders,
	ApiMethod,
	ApiService,
} from '@services/api.service';
import React from 'react';
// import { MimoRoutes } from 'Routes/router.enumm';
import { createContext, useContext, useState } from 'react';

export function useApi() {
	return useContext(ApiContext);
}

export const ApiContext = createContext({} as ApiData);

export function ApiProvider({ token, children }: any) {
	const [api, setApi] = useState<ApiData | null>(null);
	const apiService = new ApiService(token);

	useState(() => {
		setApi({
			setCustomer: (id: string) => apiService.setCustomer(id),
			withHeaders: (headers: ApiHeaders) =>
				apiService.withHeaders(headers),
			get: (url: string) => apiService.get(url),
			put: (url: string, body: any) => apiService.put(url, body),
			post: (url: string, body: any) => apiService.post(url, body),
			patch: (url: string, body: any) => apiService.patch(url, body),
			delete: (url: string) => apiService.delete(url),
			custom: (url: string, body?: any, method?: ApiMethod) =>
				apiService.custom(url, body, method),
		});
	});

	return (
		<ApiContext.Provider value={api!}>
			{api && children}
		</ApiContext.Provider>
	);
}
