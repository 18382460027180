import React from 'react';
import { COLOR_TAG } from '@utils/consts';
import { Ellipse } from './ellipse';

interface LiveTagProps {
	text: string;
	theme: string;
}

export function LiveTag(props: LiveTagProps) {
	return (
		<div
			className={`mimo-pip--inline-flex mimo-pip--items-center mimo-pip--justify-center mimo-pip--shadow-[0px_2px_16px_rgba(0,0,0,0.25)] mimo-pip--h-[17px] mimo-pip--p-1 mimo-pip--space-x-1
            mimo-pip--sm:h-[33px] mimo-pip--rounded mimo-pip--sm:px-3 mimo-pip--sm:py-2 mimo-pip--sm:space-x-2`}
			style={{ backgroundColor: COLOR_TAG[props.theme].bg }}
		>
			<Ellipse color={COLOR_TAG[props.theme].icon} />
			<span
				className={`mimo-pip--uppercase mimo-pip--font-semiBold mimo-pip--text-[7px] mimo-pip--sm:text-12`}
				style={{ color: COLOR_TAG[props.theme].txt }}
			>
				{props.text}
			</span>
		</div>
	);
}
