import { MimoPlayerOptions } from '@utils/types';

export const DEFAULT_OPTIONS: MimoPlayerOptions = {
	debug: false,
	liveId: 'live-id-not-set',
	customerId: 'customer-id-not-set',
	useShadowDom: true,
	configEndpoint:
		import.meta.env.VITE_CONFIG_ENDPOINT || 'configEndpoint-not-set',
};

export const firebaseConfig = {
	appId: import.meta.env.VITE_FIREBASE_APPID.trim(),
	apiKey: import.meta.env.VITE_FIREBASE_APIKEY.trim(),
	projectId: import.meta.env.VITE_FIREBASE_PROJECTID.trim(),
	authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN.trim(),
	databaseURL: import.meta.env.VITE_FIREBASE_DATABASEURL.trim(),
	storageBucket: import.meta.env.VITE_FIREBASE_STORAGEBUCKET.trim(),
	messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGINGSENDERID.trim(),
};

export const URL_CONFIG = {
	//_ MIMO_LIVE_ID: liveId!,
	MIMO_API_VERSION: 'v2',
	// MIMO_CUSTOMER_ID: customerId ?? '',
	// MIMO_URL_LIVE: liveUrl ?? '',
};

export const COLOR_TAG: any = {
	'tag-dark': {
		bg: '#18191A',
		txt: '#FFFFFF',
		icon: '#FF3354',
		cta_icon: '#FFFFFF',
	},
	'tag-light': {
		bg: '#FFFFFF',
		txt: '#000000',
		icon: '#FF3354',
		cta_icon: '#FF3354',
	},
	'tag-red': {
		bg: '#FF3354',
		txt: '#FFFFFF',
		icon: '#FFFFFF',
		cta_icon: '#FFFFFF',
	},
};
