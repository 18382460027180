import React from 'react';

export function SoundIcon() {
	return (
		<svg
			width="22"
			height="21"
			viewBox="0 0 22 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.3492 17.5851L6 13H2C0.89543 13 0 12.1046 0 11V7.00007C0 5.8955 0.89543 5.00007 2 5.00007H6L11.3492 0.415041C11.9979 -0.140969 13 0.319941 13 1.17429V16.8258C13 17.6802 11.9979 18.1411 11.3492 17.5851ZM6 11C6.47742 11 6.9391 11.1708 7.30158 11.4815L11 14.6516V3.34851L7.30158 6.51858C6.9391 6.82928 6.47742 7.00007 6 7.00007H2V11H6Z"
				fill="white"
			/>
			<path
				d="M16.604 4.80548C16.2731 4.36334 15.6332 4.36693 15.2426 4.75746C14.8521 5.14798 14.8623 5.77712 15.1675 6.23745C15.3446 6.5047 15.4961 6.78902 15.6194 7.08665C15.8707 7.69335 16 8.34345 16 9.00005C16 9.65675 15.8707 10.3068 15.6194 10.9135C15.4961 11.2111 15.3446 11.4954 15.1675 11.7627C14.8623 12.223 14.8521 12.8522 15.2426 13.2427C15.6332 13.6332 16.2731 13.6368 16.604 13.1947C16.953 12.7284 17.2433 12.2193 17.4672 11.6788C17.8189 10.8295 18 9.91935 18 9.00005C18 8.08085 17.8189 7.17055 17.4672 6.32131C17.4532 6.28753 17.4389 6.25387 17.4244 6.22034C17.2068 5.71735 16.9312 5.24261 16.604 4.80548Z"
				fill="white"
			/>
			<path
				d="M18.0534 1.9467C18.4537 1.5464 19.1069 1.54359 19.4682 1.97941C19.5558 2.0851 19.6414 2.19238 19.725 2.30119C20.3098 3.06288 20.7936 3.89953 21.1627 4.79054C21.2318 4.95736 21.2967 5.12568 21.3574 5.29534C21.7822 6.48294 22 7.73611 22 9.00001C22 10.4446 21.7155 11.875 21.1627 13.2096C20.7409 14.2279 20.1692 15.1751 19.4682 16.0207C19.1069 16.4565 18.4537 16.4537 18.0534 16.0534C17.6531 15.6531 17.6584 15.0073 18.0103 14.5639C18.526 13.9141 18.9499 13.1945 19.2686 12.425C19.7184 11.3392 19.9499 10.1754 19.9499 9.00001C19.9499 7.82471 19.7184 6.66092 19.2686 5.57507C18.9499 4.80561 18.526 4.08598 18.0103 3.43621C17.9663 3.38078 17.9278 3.32219 17.8947 3.26131C17.6634 2.83517 17.7031 2.29696 18.0534 1.9467Z"
				fill="white"
			/>
		</svg>
	);
}
