import React from 'react';
import { App } from 'App';
import { MimoPlayerOptions } from '@utils/types';
import { mergeOptions } from '@utils/configUtil';
import styles from '@styles/index.css?inline';
import ReactDOM from 'react-dom/client';

export const fontScript =
	'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"';

class MimoPip extends HTMLElement {
	constructor(options: MimoPlayerOptions = {}) {
		super();

		const normalizedOptions = mergeOptions({}, options);

		const rootElem = normalizedOptions.useShadowDom
			? this.attachShadow({
					mode: 'open',
			  })
			: this;

		ReactDOM.createRoot(rootElem as HTMLElement).render(
			<App options={normalizedOptions} />
		);

		this.initStyles(rootElem);
	}

	getAttributeValue<T>(
		key: string,
		casting: (v: string) => T
	): T | undefined {
		const value = this.getAttribute(key);
		if (value === null || value === undefined) {
			return undefined;
		}
		return casting(value);
	}

	initStyles(rootElem: Node) {
		const styleElem = document.createElement('style');

		styleElem.innerHTML = styles;

		rootElem.appendChild(styleElem);

		this.includeFontScript();
	}

	/**
	 * Loads the font-script which needs to be included "outside" the shadow-dom
	 */
	includeFontScript() {
		const id = 'mimofrontscript';

		if (document.getElementById(id)) {
			// script already loaded by other player
			return;
		}

		const linkNode = document.createElement('link');
		linkNode.id = id;
		linkNode.type = 'text/css';
		linkNode.rel = 'stylesheet';
		linkNode.href = fontScript;
		document.head.appendChild(linkNode);
	}
}

/**
 * Defines the web-component
 */

customElements.define('mimo-pip', MimoPip);
(window as any).MimoPip = MimoPip;
(function () {
	const currentScript =
		document.currentScript ||
		(function () {
			return document.getElementById('mimo-pip-sdk');
		})();

	document.querySelector('body')?.appendChild(
		new MimoPip({
			customerId: currentScript?.getAttribute('data-customerid'),
			liveId: currentScript?.getAttribute('data-liveid'),
		})
	);
})();
