import { FirebaseApp, deleteApp, getApp, initializeApp } from 'firebase/app';
import 'firebase/database';
import { useEffect, useRef } from 'react';
import {
	useObject as _useObject,
	useObjectVal as _useObjectVal,
} from 'react-firebase-hooks/database';
import { useEmbedParams } from '@providers/embedParams.provider';
import { firebaseConfig } from '@utils/consts';

initializeApp(firebaseConfig);

export type UseFirebaseOptions = {
	databaseURL: string;
};

export function useFirebase(appName: string, options: UseFirebaseOptions) {
	const firebaseRef = useRef<FirebaseApp>();

	useEffect(() => {
		try {
			firebaseRef.current = getApp(appName);
		} catch (e) {
			firebaseRef.current = initializeApp(options, appName);
		}

		return () => {
			// deleteApp(firebaseRef.current!)
		};
	}, [options, appName]);

	return firebaseRef?.current;
}

function wrapFirebaseFnWithErrorLog<T>(fn: T): T {
	const fn2 = ((...args: any[]) => {
		const arr = (fn as any)(...args);
		const error = arr[2];
		const params = useEmbedParams();

		if (error) {
			console.error(error);
			if (params.debug) {
				throw error;
			}
		}

		return arr;
	}) as any;

	return fn2 as T;
}

/** Returns the exact same firebase function, but wrapped with an error logger and/or throw on debug mode */
export const useObjectVal = wrapFirebaseFnWithErrorLog(_useObjectVal);
export const useObject = wrapFirebaseFnWithErrorLog(_useObject);
