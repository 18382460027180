import React from 'react';

interface LiveStreamIconProps {
	color: string;
}

export function LiveStreamIcon(color: LiveStreamIconProps) {
	return (
		<svg
			width="15"
			height="11"
			viewBox="0 0 15 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.24295 0.491293C3.45042 0.698759 3.45042 1.03513 3.24295 1.24259C0.891672 3.59388 0.891672 7.40606 3.24295 9.75734C3.45042 9.9648 3.45042 10.3012 3.24295 10.5086C3.03549 10.7161 2.69912 10.7161 2.49165 10.5086C-0.274561 7.74243 -0.274561 3.25751 2.49165 0.491293C2.69912 0.283827 3.03549 0.283827 3.24295 0.491293ZM12.509 0.491293C15.2752 3.25751 15.2752 7.74243 12.509 10.5086C12.3015 10.7161 11.9652 10.7161 11.7577 10.5086C11.5502 10.3012 11.5502 9.9648 11.7577 9.75734C14.109 7.40606 14.109 3.59388 11.7577 1.24259C11.5502 1.03513 11.5502 0.698759 11.7577 0.491293C11.9652 0.283827 12.3015 0.283827 12.509 0.491293ZM5.24642 2.49476C5.45389 2.70223 5.45389 3.0386 5.24642 3.24606C4.00163 4.49086 4.00163 6.50907 5.24642 7.75387C5.45389 7.96133 5.45389 8.2977 5.24642 8.50517C5.03896 8.71264 4.70259 8.71264 4.49512 8.50517C2.83539 6.84544 2.83539 4.15449 4.49512 2.49476C4.70259 2.2873 5.03896 2.2873 5.24642 2.49476ZM10.5055 2.49476C12.1653 4.15449 12.1653 6.84544 10.5055 8.50517C10.2981 8.71264 9.96169 8.71264 9.75423 8.50517C9.54676 8.2977 9.54676 7.96133 9.75423 7.75387C10.999 6.50907 10.999 4.49086 9.75423 3.24606C9.54676 3.0386 9.54676 2.70223 9.75423 2.49476C9.96169 2.2873 10.2981 2.2873 10.5055 2.49476ZM7.50032 4.43747C8.08713 4.43747 8.56282 4.91316 8.56282 5.49997C8.56282 6.08677 8.08713 6.56247 7.50032 6.56247C6.91352 6.56247 6.43782 6.08677 6.43782 5.49997C6.43782 4.91316 6.91352 4.43747 7.50032 4.43747Z"
				fill={`${color.color}`}
			/>
		</svg>
	);
}
