import React from 'react';

export function CloseIcon() {
	return (
		<svg
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.0468 0.578946C11.3679 0.257795 11.8886 0.257786 12.2098 0.578946C12.5309 0.900097 12.5309 1.4208 12.2098 1.74195L7.55771 6.39401L12.2098 11.046C12.5309 11.3671 12.5309 11.8878 12.2098 12.209C11.8886 12.5301 11.3679 12.5301 11.0468 12.209L6.39471 7.557L1.74275 12.209C1.73772 12.214 1.73266 12.2189 1.72754 12.2238C1.40536 12.53 0.895872 12.5251 0.579737 12.209C0.338873 11.9681 0.278651 11.615 0.399087 11.318C0.439227 11.219 0.499449 11.1262 0.579737 11.046L5.23172 6.39401L0.579737 1.74199C0.258577 1.42084 0.258577 0.900138 0.579737 0.578987C0.900888 0.257827 1.42159 0.257827 1.74274 0.578987L6.39471 5.23094L11.0468 0.578946Z"
				fill="white"
			/>
		</svg>
	);
}
